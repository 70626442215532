<template>
  <div class="login">
    <img class="login-bg" src="@/assets/login.png" alt="" />
    <div class="login-box">
      <div class="login-title">登录</div>
      <div class="login-form">
        <div class="login-form-item">
          <div class="form-item-left">手机号：</div>
          <div class="form-item-right">
            <el-input v-model="form.phone" placeholder=" " />
          </div>
        </div>

        <div class="login-form-item">
          <div class="form-item-left">密码：</div>
          <div class="form-item-right">
            <el-input v-model="form.password" type="password" placeholder=" " />
          </div>
        </div>

        <div class="login-form-item" v-if="false">
          <div class="form-item-left">验证码：</div>
          <div class="form-item-right">
            <el-input v-model="form.code" placeholder=" " />
            <el-button
              type="primary"
              size="mini"
              @click="sendCode"
              :loading="codeLoading"
              :disabled="count !== 60"
              >{{ count === 60 ? "发送验证码" : count + "秒再试" }}</el-button
            >
          </div>
        </div>
        <el-button
          class="submit"
          type="primary"
          @click="submit"
          :loading="submitLoading"
          >登 录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { login, sendIdentificationCode } from "@/api/base.js";

export default {
  name: "Login",
  data() {
    return {
      count: 60,
      timeId: null,
      codeLoading: false,
      submitLoading: false,
      form: {
        phone: "",
        password: "",
        code: "",
      },
    };
  },
  methods: {
    // 提交
    submit() {
      const { phone, password, code } = this.form;
      //  || !code
      if (!phone || !password) {
        this.$message.warning("请填写信息");
        return;
      }
      let data = {
        phone,
        password,
        identification_code: code,
      };
      // console.log(data);
      this.submitLoading = true;
      login(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("登录成功！");
            window.localStorage.setItem("wildanimals_token", res.data.token);
            window.localStorage.setItem("user_name", res.data.userName);
            this.$store.commit("CHANGE_SETTING", {
              key: "userName",
              value: res.data.userName,
            });
            window.localStorage.setItem("user_id", res.data.userId);
            this.$store.commit("CHANGE_SETTING", {
              key: "userId",
              value: res.data.userId,
            });

            // 登录时间戳
            window.localStorage.setItem(
              "token_timestamp",
              JSON.stringify(new Date().getTime())
            );
            this.$router.push({ name: "Home" });
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 发送验证码
    sendCode() {
      if (this.form.phone === "") {
        this.$message.warning("请输入手机号");
        return;
      }
      this.codeLoading = true;
      sendIdentificationCode({
        phone: this.form.phone,
        type: 2,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("发送成功！");
            this.timeId = setInterval(() => {
              if (this.count === 0) {
                clearInterval(this.timeId);
                this.timeId = null;
                this.count = 61;
              }
              this.count -= 1;
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .login {
    height: calc(100vh - 120px - 170px);
    .login-box {
      width: 350px;
      top: 50%;
      right: 20%;
      transform: translateY(-50%);
      padding: 0 2%;
    }
    .login-title {
      font-size: 24px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .login {
    height: calc(100vh - 60px - 70px);
    .login-box {
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 4%;
    }
    .login-title {
      font-size: 18px;
    }
  }
}
.login {
  width: 100%;
  position: relative;

  .login-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
  .login-box {
    position: absolute;
    background: #fff;
    .login-title {
      margin-top: 6%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0175e2;
      padding-bottom: 4%;
      border-bottom: 1px solid #a1a1a1;
    }
    .login-form {
      padding-bottom: 4%;
      border-bottom: 1px solid #b7b7b7;
      margin-bottom: 2%;
      .login-form-item {
        margin-top: 8%;
        display: flex;
        align-items: center;
        padding: 0 2%;
        .form-item-left {
          width: 26%;
        }
        .form-item-right {
          width: 74%;
          display: flex;

          .el-button {
            margin-left: 6%;
          }
        }
      }
      .submit {
        margin-top: 8%;
        width: 100%;
        font-size: 16px;
      }
    }
  }
}
</style>